import { useEffect, useState } from "react";
//import { getuserName } from '../helper/helper'
import axiosInstance from '../authModule/helper/axios-url'

const axios = axiosInstance;

/** custom hook */
export default function useFetchChannels(){
    const [getData, setData] = useState({ isLoading : false, apiData: null, status: null, serverError: null })

    useEffect(() => {

        const allChannels = async () => {
            try {
               // const { username } = await getuserName();
                
                setData(prev => ({ ...prev, isLoading: true}));
                
                const { data, status } =  await axios.get(`/api/channels`);
                //console.log(data)
                if(status === 201){
                    setData(prev => ({ ...prev, isLoading: false}));
                    setData(prev => ({ ...prev, apiData : data, status: status }));
                }

                setData(prev => ({ ...prev, isLoading: false}));
                
            } catch (error) {
                setData(prev => ({ ...prev, isLoading: false, serverError: error }))
            }
        };

        allChannels()

    }, []);

    return [getData];    
}