import React from 'react'
import pageNotFound from '../assets/pnf.png'

const NotFoundPage = () => {
  return (
    <>
    <div className='p-3 items-center  flex justify-between cursor-pointer bg-slate-100 border-b-2 border-slate-400 md:px-28'>
        {/* <AiOutlineHome size={30}  className='cursor-pointer' onClick={goBack}/> */}
        <span className='text-xl font-extrabold'>Trade Copier</span>
    </div>
   
    <div className='container '>
        <div className='flex flex-col items-center justify-center md:mt-16 mt-8'>
            <span className='text-4xl text-blue-800 font-bold'>OOPS!</span>
            <img src={pageNotFound} alt="pagenotfound" />
            <span className='text-lg text-blue-800 font-bold'>Page Not Found.</span>
        </div>
    </div>
    </>
  )
}

export default NotFoundPage