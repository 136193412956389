import axios from 'axios';
import { CLIENT_ID, APP_SECRET } from '../../config/config'
//create subscription plans
export const createSubscriptionPlans = async (prices) => {

    let updatedPrices = [...prices];
    
    for (let i = 0; i < prices.length; i++) {
      const packagePrice = prices[i];
      if (packagePrice !== null) {

        let periodDuration;
            if (i === 0) {
                periodDuration = 1; // 1 month for the first index
            } else if (i === 1) {
                periodDuration = 3; // 3 months for the second index
            } else if (i === 2) {
                periodDuration = 6; // 6 months for the third index
            } else if (i === 3) {
                periodDuration = 12; // 12 months for the fourth index
            } else {
                // Handle additional periods as needed
                periodDuration = 0;
            }

        const selfPlan = await createSubscriptionPlan(packagePrice);
        const cloudPlan = await createSubscriptionPlan(packagePrice + (periodDuration * 20)); 

        updatedPrices[i] = { price:packagePrice, period: periodDuration, selfPlan, cloudPlan };
      }
    }
    return updatedPrices;
};

//create subscription plan
export const createSubscriptionPlan = async (packagePrice) => {
    try {
        const clientId = 'AVey3Fe_n6ZydjO6QcqZ-qGXgXb6lc3aJdJDoxjsdqnvbNudcemjg6suKpA3Oslw_7HFMLF3_3FtjrUj';
        const clientSecret = 'EOz54j59JCN-eLuZghPROHJ1983JAeF2Dt7etFG_ZEsbPQVZJbGaSLEM9eiw-NodzBIZ5FNsUIndc2wD';
        
        // PayPal OAuth token endpoint
        const tokenUrl = 'https://api-m.sandbox.paypal.com/v1/oauth2/token'; // Sandbox URL, change to production URL if needed

        // Encode client ID and secret for Basic Authentication
        const authString = `${clientId}:${clientSecret}`;

        const basicAuth = btoa(`${clientId}:${clientSecret}`) ;

        // Request headers
        const headers = {
            'Authorization': `Basic ${basicAuth}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        // Request body for getting access token
        const data = 'grant_type=client_credentials';

        // Get access token
        const response = await axios.post(
        'https://api.sandbox.paypal.com/v1/oauth2/token',
        'grant_type=client_credentials',
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${basicAuth}`
            }
        }
        );

        const accessToken = response.data.access_token;

        //get the product id
        const productId = await createProduct(accessToken);
        console.log(productId);
        
        // Subscription plan creation endpoint
        const apiUrl = 'https://api-m.sandbox.paypal.com/v1/billing/plans'; // Sandbox URL, change to production URL if needed

        const requestBody = {
        product_id: `${productId}`, // Replace your actual product ID
        name: 'Subscribe TTC',
        status: 'ACTIVE',
        description: 'Your plan description',
        billing_cycles: [
            {
                frequency: {
                    interval_unit: 'DAY',
                    interval_count: 1
                },
                tenure_type: 'REGULAR',
                sequence: 1,
                total_cycles: 0,
                pricing_scheme: {
                    fixed_price: {
                        value: packagePrice.toString(), // Use the package variable here
                        currency_code: 'USD'
                    }
                }
            }
        ],
        payment_preferences: {
            auto_bill_outstanding: true,
            setup_fee: {
                value: '0',
                currency_code: 'USD'
            },
            setup_fee_failure_action: 'CONTINUE',
            payment_failure_threshold: 3
        }
        };

        // Request headers for plan creation
        const planHeaders = {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Prefer': 'return=representation'
        };

        // Make POST request to create the subscription plan
        const responsePlan = await axios.post(apiUrl, requestBody, { headers: planHeaders });

        // Handle successful response
        console.log('Subscription plan created successfully:', responsePlan.data.id);

        const planId = responsePlan.data.id;
        return planId;

    } catch (error) {
        // Handle error
        console.error('Error creating subscription plan:', error);
    }
};

//update the subscription paln
// export const updatePricingScheme = async (planId, newPrice) => {
//     console.log('plan id :', planId, 'update Price :', newPrice)
//     try {

//         // const clientId = CLIENT_ID;
//         // const clientSecret = APP_SECRET;
        

//         const clientId = 'AVey3Fe_n6ZydjO6QcqZ-qGXgXb6lc3aJdJDoxjsdqnvbNudcemjg6suKpA3Oslw_7HFMLF3_3FtjrUj';
//         const clientSecret = 'EOz54j59JCN-eLuZghPROHJ1983JAeF2Dt7etFG_ZEsbPQVZJbGaSLEM9eiw-NodzBIZ5FNsUIndc2wD';
        

//         const tokenUrl = 'https://api-m.sandbox.paypal.com/v1/oauth2/token'; // Sandbox URL, change to production URL if needed

//         const basicAuth = btoa(`${clientId}:${clientSecret}`) ;

//         // Request headers
//         // const headers = {
//         //     'Authorization': `Basic ${basicAuth}`,
//         //     'Content-Type': 'application/x-www-form-urlencoded'
//         // };

//         // Request body for getting access token
//         // const data = 'grant_type=client_credentials';

//         // Get access token
//         const response = await axios.post(
//         'https://api.sandbox.paypal.com/v1/oauth2/token',
//         'grant_type=client_credentials',
//         {
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//                 'Authorization': `Basic ${basicAuth}`
//             }
//         }
//         );

//         const accessToken = response.data.access_token;

//         console.log('access token : ',accessToken)

//         // Subscription plan update endpoint
//         const apiUrl = `https://api-m.sandbox.paypal.com/v1/billing/plans/${planId}/update-pricing-schemes`;

//         const requestBody = {
//             pricing_schemes: [
//                 {
//                     billing_cycle_sequence: 1,
//                     pricing_scheme: {
//                         fixed_price: {
//                             value: newPrice.toString(), // Use the package variable here
//                             currency_code: 'USD'
//                         }
//                     }
//                 }
//             ]
//         };

//         // Request headers for plan update
//         const planHeaders = {
//             'Authorization': `Bearer ${accessToken}`,
//             'Content-Type': 'application/json'
//         };

//         // Make POST request to update pricing scheme
//         const planResponse = await axios.post(apiUrl, requestBody, { headers: planHeaders });

//         // Handle successful response
//         console.log('Subscription plan price updated successfully:', planResponse);

//         return planResponse.data; // Return updated plan details

//     } catch (error) {
//         // Handle error
//         console.error('Error updating subscription plan price:', error);
//         throw error;
//     }
// };

export const updatePricingScheme = async (planId, newPrice) => {
    console.log('plan id :', planId, 'update Price :', newPrice)
    try {

        // const clientId = CLIENT_ID;
        // const clientSecret = APP_SECRET;
        
        const clientId = 'AVey3Fe_n6ZydjO6QcqZ-qGXgXb6lc3aJdJDoxjsdqnvbNudcemjg6suKpA3Oslw_7HFMLF3_3FtjrUj';
        const clientSecret = 'EOz54j59JCN-eLuZghPROHJ1983JAeF2Dt7etFG_ZEsbPQVZJbGaSLEM9eiw-NodzBIZ5FNsUIndc2wD';
        
        const basicAuth = btoa(`${clientId}:${clientSecret}`) ;

        // Get access token
        const response = await axios.post(
        'https://api.sandbox.paypal.com/v1/oauth2/token',
        'grant_type=client_credentials',
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${basicAuth}`
            }
        }
        );

        const accessToken = response.data.access_token;

        // Subscription plan update endpoint
        const apiUrl = `https://api-m.sandbox.paypal.com/v1/billing/plans/${planId}/update-pricing-schemes`;

        //console.log(apiUrl)

        const requestBody = {
                pricing_schemes: [
                    {
                        billing_cycle_sequence: 1,
                        pricing_scheme: {
                            fixed_price: {
                                value: newPrice.toString(), // Use the package variable here
                                currency_code: 'USD'
                            }
                        }
                    }
                ]
        };

        // Request headers for plan update
        const planHeaders = {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        };

        // Make POST request to update pricing scheme
        const planResponse = await axios.post(apiUrl, requestBody, { headers: planHeaders });

        // Handle successful response
        console.log('Subscription plan price updated successfully:', planResponse);

        return planResponse; // Return updated plan details

    } catch (error) {
        // Handle error
        console.error('Error updating subscription plan price:', error);
        throw error;
    }
};


//create product or get product id
const createProduct = async (accessToken) => {
    console.log('get the product id')
    try {
        const apiUrl = 'https://api-m.sandbox.paypal.com/v1/catalogs/products'; // Sandbox URL, change to production URL if needed

        // Make GET request to retrieve product list
        const productListResponse = await axios.get(apiUrl, { headers: { 'Authorization': `Bearer ${accessToken}` } });
        const productList = productListResponse.data.products;

        // Check if the product list is empty
        if (productList.length === 0) {
            console.log('Product list is empty. Creating a new product...');

            const requestBody = {
                name: 'TTC Subscription',
                type: 'SERVICE'
            };

            // Request headers for product creation
            const productCreateHeaders = {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Prefer': 'return=representation'
            };

            // Make POST request to create the product
            const responsePlan = await axios.post(apiUrl, requestBody, { headers: productCreateHeaders });

            // Handle successful response
            console.log('Product created successfully:', responsePlan.data);

            // Return the product ID
            return responsePlan.data.id;
        } else {
            console.log('Product list is not empty. Using the first product.');
            // Return the ID of the first product
            return productList[0].id;
        }
    } catch (error) {
        console.error('Error creating/retrieving product:', error);
        throw error; // Re-throw the error for handling in the calling function
    }
};