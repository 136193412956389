import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { LineChart } from '@mui/x-charts/LineChart';

// image 
import img from '../../assets/img/user.png';

// sweet alert
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import {FreeMode, Navigation, Thumbs, Autoplay} from 'swiper';

//import the axios function
import { getChannels, getuserName, createTrader, getSubchannel, unsubscribeChannel, getChannelById } from '../authModule/helper/helper';

// import payment
import Payments from './Payments';

const ChannelDetails = (props) => {
  // channel id
  //const { channelId } = useParams();
  
  const channelId = props.channelId;
  const email = props.email
  
  //user state 
  const [channel, setChannel] = useState([]); //channel
  const [isOpen, setIsOpen] = useState(false); // payment popup
  
  //const [email, setEmail] = useState('');
  const [subscribedData, setSubscribedData] = useState([]);

  // swiper
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // load the 
  useEffect(() => {

    async function fetchData() {
      try {
        const response = await getChannelById(channelId);
        const chan = response.data;
        //console.log(chan)
        setChannel(chan);
      } catch (error) {
        console.error(error);
      }
    }
    
    fetchData();
    
  }, [channelId]);

// Payment Popup
const togglePopup = () => {
  setIsOpen(!isOpen);
  //setChannel(channel);
 //console.log(channel.account)
};

//outside click
const refPay = useRef(null);
//outside click
useEffect(() => {
  // Function to handle outside click
  function handleClickOutside(event) {
    if (refPay.current && !refPay.current.contains(event.target)) {
      setIsOpen(false)
    } else {
      //setIsClickedOutside(false);
    }
  }
  // Bind the event listener
  document.addEventListener('mousedown', handleClickOutside);

  // Clean up the event listener when the component is unmounted
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

//get the user email
// function getUserEmail(){
//   getuserName().then(result => {
//     const userEmail = result.username;
//     //console.log(userEmail)
//     setEmail(userEmail);
//     subChannels(userEmail)

//   })
// }
//get the subscribe channels
function subChannels(email){
  getSubchannel(email)
  .then((response) => {
    const subscribed = response.data.map((sub) => sub.channelName);
    setSubscribedData(subscribed);
  });
}

//unsubscribe the channel
function unsubscribe(chanels) {
  Swal.fire({
    title: 'Do you wanna unsubscribe?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    customClass:{
      popup:'my-popup-class'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      let data = { chanels, email };
      console.log(data)
      unsubscribeChannel(data);
    } else {
      console.log('Unsubscribe canceled.');
    }
  });
}

console.log(channel.subCount)

  return (
    <div className='bg-slate-100  rounded-xl shadow-md shadow-sky-900 z-40 w-3/5 h-auto'>

      <Card>
        {/* Card Content */}

        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <CardContent className='m-1'>
            {/* header section */}
            <div className='w-full mb-2 h-24 relative '>
              {/* cover image */}
              {channel.cover ? (
                <img src={channel.cover} alt="Banner" className='h-full w-full object-cover z-0' />
              ) : (
                <div className='bg-slate-200 w-full h-full'></div>
              )}
              
              {/* logo and name */}
              <div className='justify-start '>
                {/* Your centered content goes here */}
                <div className={`h-2/4 `}>
                  <div className=" justify-start absolute top-0 left-0 flex items-center z-30">
                    <img
                      src={channel.logo || img}
                      className="w-24 h-24 rounded-full object-cover p-2"
                      alt="Channel Logo"
                    />
                    <div className="w-1/2 flex flex-col justify-center">
                      <h3 className="font-bold text-xl text-teal-100">
                        {channel.data?.client?.channelName || ''}
                      </h3>
                      {/* <p>High achieve</p> */}
                    </div>
                  </div>
                </div>
            </div>
            </div> 

            {/* banners slide */}
            <div className='h-96'>
              <Swiper
              style={{"--swiper-navigation-color":"white"}}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
              autoplay={{ delay: 6000 }}        
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              className='mySwiper2'
              >
                {channel.flayers && channel.flayers.length > 0 && (
                  channel.flayers.map((banner, index) => (
                    <SwiperSlide key={index}>
                      <img src={banner} alt={`Banner ${index + 1}`} />
                    </SwiperSlide>
                  ))
                )}

              </Swiper>

              {/* this is the previewer */}
              <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={5}   
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className='mySwiper'
              >
                {channel.flayers && channel.flayers.length > 0 && (
                  channel.flayers.map((banner, index) => (
                    <SwiperSlide key={index}>
                      <img src={banner} alt={`Banner ${index + 1}`} />
                    </SwiperSlide>
                  ))
                )}

              </Swiper>
            </div>

            {/* bottom */}  
            <Typography>Packages</Typography> <hr/>
            <div className='flex justify-center mt-2'>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
                  {channel.price && channel.price.length > 0 && (
                      channel.price.map((item, index) => (
                          item && item.price !== null && item.price > 0 && (
                              <div key={index} className='p-3 bg-slate-100 rounded-md'>
                                  {`${[1, 3, 6, 12][index]} Month : $ ${item.price}`}
                              </div>
                          )
                      ))
                  )}
              </div>
            </div>

          {channel.subCount ? (
            <div className='grid grid-cols-6 mt-5'>
              <div className='p-2 rounded-2xl text-center flex justify-center items-center'>
                  Subscibers : {channel.count} 
              </div>
            </div>  ):(null)
          }
          </CardContent>
        </div>
       
        <div className='float-right'>
        {
          props.props.subsBtn ? (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            {
              subscribedData && subscribedData.includes(channel.data?.client?.channelName) ? (
                <Button 
                onClick={() => unsubscribe(channel.data?.client?.channelName)}
                variant='outlined' 
                sx={{borderRadius:3}} 
                color='primary'>Unsubscribe</Button>
              ):(
                <Button 
                onClick={() => togglePopup()}
                variant='contained' 
                sx={{borderRadius:3}} 
                color='primary'>Subscribe</Button>
              )
            }
          </CardActions>
          ) : (null)
        }
        </div>

      </Card>

      {/* Payment Popup */}
      {
      isOpen && (
        <div className='bg-opacity-100 opacity-100 fixed inset-0 z-20'>
          <div className="fixed inset-0 backdrop-blur-sm" />
          <div ref={refPay} className="flex h-screen justify-center items-center">
            {/* add the payment componet */}
            <Payments channelId={channelId}/>
          </div>
        </div> 
      )
      }
    </div>
  )
}

export default ChannelDetails