import React,{ useState } from 'react'

// react Icons
import { ImCross } from 'react-icons/im';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

import { BsPlusLg } from 'react-icons/bs';

// create sub plan
import {createSubscriptionPlan, updatePricingScheme} from '../PaypalSubscription/PaypalSub';

// sweet alert
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import convetBase64 from '../authModule/helper/convert';
import {updateChannel } from '../authModule/helper/helper';

const EditChanel = ({details, index}) => {

  //image-upload
  const [profilePic, setprofilePic] = useState(details.logo);
  //image-upload
  const [bannerPic, setBannerPic] = useState(details.cover);
  //flayers   
  const [images, setImages] = useState(details.flayers);
  //price
  const [editPrice, setEditPrice] = useState(details.price);  
  // Add a state variable for subCount
  const [subCount, setSubCount] = useState(details.subCount); 
  // updating btn
  const [updated, setUpdated] = useState(false);

    // for image upload
    const onUpload = async e =>{
        const base64Logo = await convetBase64(e.target.files[0]);
        setprofilePic(base64Logo); 
    }
    // Banner uploaed
    const onUploadBanner = async e =>{
        //console.log('first')
        const base64Banner = await convetBase64(e.target.files[0]);
        setBannerPic(base64Banner); 
    }

    const handleRemoveImage = (index) => {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
      //setSelectedImageCount(selectedImageCount - 1);
    };
  
    const handleUpload = async e => {
        if (images.length < 5) {
          const newImage = await convetBase64(e.target.files[0]);
          setImages([...images, newImage]);
        }
    };

    // const handlePriceChange = (index, value) => {
    //     setEditPrice(prevEditPrice => {
    //         const updatedPrice = [...prevEditPrice]; // Create a shallow copy of the array
    
    //         // Check if the price has changed
    //         if (updatedPrice[index] && updatedPrice[index].price !== value) {
    //             // Update the price at the specified index
    //             updatedPrice[index] = { ...updatedPrice[index], price: value };
    
    //             // Mark the item as changed by updating its previousPrice
    //             updatedPrice[index].previousPrice = updatedPrice[index].price;
    //         } else if (!updatedPrice[index]) {
    //             // If price information is not present, add new price
    //             let periodDuration;
    //             if (index === 0) {
    //                 periodDuration = 1; // 1 month for the first index
    //             } else if (index === 1) {
    //                 periodDuration = 3; // 3 months for the second index
    //             } else if (index === 2) {
    //                 periodDuration = 6; // 6 months for the third index
    //             } else if (index === 3) {
    //                 periodDuration = 12; // 12 months for the fourth index
    //             } else {
    //                 // Handle additional periods as needed
    //                 periodDuration = 0;
    //             }
    //             updatedPrice[index] = { price: value , period: periodDuration };
    //         }
    
    //         return updatedPrice;
    //     });
    // };
    
    const handlePriceChange = (index, value) => {
        setEditPrice(prevEditPrice => {
            const updatedPrice = [...prevEditPrice]; // Create a shallow copy of the array
    
            // Check if the price has changed
            if (updatedPrice[index] && updatedPrice[index].price !== value) {
                // Update the price at the specified index
                updatedPrice[index] = { ...updatedPrice[index], price: value };
    
                // Initialize previousPrice if it's not set
                if (typeof updatedPrice[index].previousPrice === 'undefined') {
                    updatedPrice[index].previousPrice = value;
                }
            } else if (!updatedPrice[index]) {
                // If price information is not present, add new price
                let periodDuration;
                if (index === 0) {
                    periodDuration = 1; // 1 month for the first index
                } else if (index === 1) {
                    periodDuration = 3; // 3 months for the second index
                } else if (index === 2) {
                    periodDuration = 6; // 6 months for the third index
                } else if (index === 3) {
                    periodDuration = 12; // 12 months for the fourth index
                } else {
                    // Handle additional periods as needed
                    periodDuration = 0;
                }
                updatedPrice[index] = { price: value, period: periodDuration, previousPrice: value };
            }
    
            return updatedPrice;
        });
    };
    

    const updateChannelData = () => {
    const cid = details.data?.client?.accountID || '';
    const data = { cid, profilePic, bannerPic, images, editPrice, subCount };
    setUpdated(true);

    const planPromises = [];

    // Iterate over each item in the editPrice array
    editPrice.forEach((item, index) => {
        // Check if the item is not an empty object and has price
        if (item && Object.keys(item).length > 0 && item.price) {
            // Check if the item has period, selfPlan, and cloudPlan properties
            if (item.period && item.selfPlan && item.cloudPlan) {
                    //console.log('1 exit price to update', item.price)
                    
                     // Check if the price has changed
                    if (item.price !== details.price[index].price) {
                        console.log('changed exit price to update', item.price);
                        planPromises.push(updatePricingScheme(item.selfPlan, item.price));
                        planPromises.push(updatePricingScheme(item.cloudPlan, parseFloat(item.price) + (item.period * 20)));
                    }   
             }
            else {
                const selfPlanPromise = createSubscriptionPlan(item.price); // Assuming cloudItem is defined
                const cloudPlanPromise = createSubscriptionPlan(parseFloat(item.price) + (item.period * 20)); // Assuming selfItem is defined

                // Wait for both promises to resolve
                Promise.all([cloudPlanPromise, selfPlanPromise]).then(([cloudPlanId, selfPlanId]) => {
                    // Add plan IDs to the item object
                    item.selfPlan = selfPlanId;
                    item.cloudPlan = cloudPlanId;

                    console.log('cloud :', cloudPlanId, 'self :', selfPlanId);
                }).catch(error => {
                    console.error('Error creating subscription plan:', error);
                });

                // Push both promises into the array
                planPromises.push(cloudPlanPromise.then(id => id), selfPlanPromise.then(id => id));
            }
        }
    });

    // After all plan promises are resolved, update the channel details
    Promise.all(planPromises).then(results => {
        console.log('All plan promises resolved:', results);

        // Now that all plan promises are resolved and editPrice is updated, update the channel
        updateChannel(data)
            .then(() => {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Update Successful',
                    showConfirmButton: false,
                    timer: 1000
                });
                setUpdated(false);
            })
            .catch((error) => {
                console.error(error);
                setUpdated(false);
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Update Failed',
                    showConfirmButton: false,
                    timer: 1000
                });
            });
    }).catch(error => {
        console.error('Error resolving plan promises:', error);
    });
};

    //console.log(editPrice)

  return (
    <>
    <div className='mx-8 my-5 justify-center'>
        <div className='w-full mb-5 h-24 relative'>
            {/* banner area */}
            <>
            <label htmlFor="banner">
                <img src={bannerPic || details.cover || ''}  alt="banner" className='h-24 w-full object-cover z-0 rounded-lg cursor-pointer' />
            </label>
            <input onChange={onUploadBanner} type="file" id='banner' name='banner' hidden />
            </>

            {/* logo and name */}
            <div className='justify-start flex '>
            {/* Your centered content goes here */}
            <div className={`h-2/4 `}>
                <div className="justify-start absolute top-0 left-0 flex items-center z-30">
            
                <div className='border border-slate-400 rounded-full p-1 m-2 object-cover'>
                    <label htmlFor="profile">
                        <img src={profilePic || details.logo || ''} className='h-20 w-20 rounded-full cursor-pointer' alt="avatar"/>
                    </label>
                    <input onChange={onUpload} type="file" id='profile' name='profile' hidden />
                </div> 

                <div className="w-1/2 flex flex-col justify-center">
                    <h3 className="font-bold text-xl text-teal-100">
                    {details.data?.client?.channelName || ''}
                    </h3>
                    {/* <p>High achieve</p> */}
                </div>
                </div>
            </div>
            </div>
        
        </div> 

        <div className='grid md:grid-cols-2 grid-col-1 py-5'>
        
            <div className='grid-cols-1 text-slate-600'>Account Id : 
                <span className='mx-2 text-slate-900'>{details.data?.client?.accountID || ''}</span>
            </div>
                    
            <div className='grid-cols-1 text-slate-600'>Platform : 
                <span className='mx-2 text-slate-900'>{details.data?.client?.platform}</span>
            </div>

            {/* <div className='grid-cols-1 text-slate-600'>Subscriber Count : 
                <span className='mx-2 text-slate-900'>{details.data?.client?.tenant}</span>
            </div> */}
        
        </div>
        
        <hr className='my-5'/>
        <Typography className='text-slate-600 my-4'>Update the Flaters</Typography>
        <div className='grid md:grid-cols-5 grid-col-2 bg-slate-200 p-2 rounded-md'>
            {images.map((flayer, index) => (
                <div key={index} className='relative'>
                <img className='w-24 h-24' src={flayer} alt={`image ${index + 1}`} />
                <button
                    className='absolute top-0  left-0 text-red-500 font-bold'
                    onClick={() => handleRemoveImage(index)}
                >
                    <ImCross color='red' />
                </button>
                </div>
            ))}
            {images.length < 5 && (
                <label htmlFor='fileInput' className='relative w-24 h-24 bg-slate-300 cursor-pointer'>
                    <BsPlusLg size={30} className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400' />
                    <input
                    id='fileInput'
                    type='file'
                    accept='image/*'
                    onChange={handleUpload}
                    className='hidden'
                    />
                </label>
            )}                                                      
        </div>

        <hr className='my-5' />
        <Typography className='text-slate-600 my-5'>Update the Prices</Typography>
        <div className='grid grid-cols-4'>
            {[1, 3, 6, 12].map((months, index) => (
                <div key={index}>
                    <InputLabel>{months} Month</InputLabel>
                    <div className="flex items-center">
                        <span>$</span>
                        <Input
                            type="text"
                            style={{ textAlign: 'center' }}
                            className='w-20 text-center ml-3'
                            // value={editPrice[index]?.price || ''}
                            // onChange={(e) => {
                            //     console.log("Changed value:", e.target.value);
                            //     handlePriceChange(index, e.target.value); // Pass the index of the price object
                            // }}
                            value={editPrice[index] !== undefined ? editPrice[index]?.price : ''}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                //console.log("Changed value:", newValue);
                                handlePriceChange(index, newValue);
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>

        <hr className='my-5' />
        <div className='grid grid-cols-2'>
            <div>
            <Typography className='text-slate-600 my-5'>Visible Sub.Count</Typography>
            <div className='flex'>
                <FormControl >
                    {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Update Sub.Count
                    </InputLabel> */}
                    <NativeSelect
                    value={subCount} // Set the value of the dropdown
                    onChange={(e) => setSubCount(e.target.value)} // Update subCount on change
                    >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                    </NativeSelect>
                </FormControl>
            </div>
            </div>
            <div>
            <Typography className='text-slate-600 my-5'>Subscriber Count</Typography>
            <>{details.count}</>
            </div>
        </div>
    </div>   

    {/* button area */}
    {details ? (
        <div className='float-right mx-8 my-5'>
            <Button 
            className='rounded-2xl'
            onClick={updateChannelData} 
            variant='contained' 
            sx={{borderRadius:3}} 
            disabled={updated}
            color='primary'>{updated ? 'Updatting...' : 'Update' }</Button>
        </div>
    ):(<></>)
    }
    </>
  )
}

export default EditChanel