import React, { useEffect, useState, useCallback } from 'react';
//user Formik
import { useFormik } from 'formik';
//paypal
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CLIENT_ID } from '../../config/config'
// mui 
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Select, MenuItem, RadioGroup, Radio, FormControlLabel } from '@mui/material';

//import the axios function
import {  getuserName, createTrader, getSubchannel, unsubscribeChannel, getChannelById } from '../authModule/helper/helper';

// tostMessage
import toast,{Toaster} from 'react-hot-toast';

// sweet alert
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

const Payments = (channelId) => {

  const [email, setEmail] = useState(''); 
  const cId = channelId.channelId;
  //console.log(cId)

  const [orderID, setOrderID] = useState(false); //paypal payment
  const [btnConfirm, setBtnConfirm] = useState(false); //Show Confirm
  const [btnpaypal, setBtnPaypal] = useState(true); //Show paypal

  const [channel, setChannel] = useState([]); //channel
  const [price, setPrice] = useState([]) //priceing

  // hosting
  const [selectedHost, setSelectedHost] = useState(null);
  const [hostAmmount, setHostAmount] = useState(0);
  
  //packages radio buttons
  const [selectedOption, setSelectedOption] = useState(null);

  const [selfHost, setSelfHost] = useState(false);
  const [cloudHost, setCloudHost] = useState(false);
  const [planId, setPlanId] = useState(""); //plan id


  const handleDropdownChange = (event) => {
    const selectedIndex = event.target.value;

    console.log(selectedIndex)
    setSelectedHost(null)
    setHostAmount(0)

    setSelectedOption(selectedIndex);
    const selectedPrice = price[selectedIndex]; // Assuming price is an array of objects
    //console.log("Selected price:", selectedPrice);
  };

  //userEffect-onload
  useEffect(() => {
    getUserEmail()
    async function fetchData() {
      try {
        const response = await getChannelById(cId);
        const chan = response.data.data.client;
        const ch_price = response.data.price;
     //  console.log(response.data.price);
        setChannel(chan);
        setPrice(ch_price)
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
 
  //get the user email
  function getUserEmail(){
    getuserName().then(result => {
      const userEmail = result.username;
      //console.log(userEmail)
      setEmail(userEmail);
      //subChannels(userEmail)
    })
  }
  
  const onApprove = (data, actions) => {
    console.log("Payment Approved. Data:", data);
  
    Swal.fire({
      icon: 'success',
      title: 'Payment Successful',
      showConfirmButton: false, 
      timer: 2000,
    });

    // Update state
    setBtnConfirm(prevState => !prevState);
    setBtnPaypal(prevState => !prevState);
  };

  // get the Amount
  const calAmount = (host, amount, index) => {
    
    const monthCharge = 20;
    const selectedMonths = [1, 3, 6, 12];
    const selectedMonth = selectedMonths[index];
    const cloudHostCharges = selectedMonth * monthCharge;
    
    if(host === 'cloud'){
      const sum = parseInt(cloudHostCharges, 10) + parseInt(amount, 10);
      setHostAmount(sum)
      // console.log(sum)
    }else{
      setHostAmount(amount)
      // console.log(amount)
    }

  }

  // submit
  const {values, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
    initialValues : {
      account : '',
      channelName: '',
      channelId: '',
      email : '',
      password : '',
      server : '',
    },
    // validationSchema : registerSchema,
    onSubmit : async values => {  
      const updatedValues = Object.assign({}, values, {
        email,
        channelId: channelId.channelId,
        channelName: channel.channelName,
        hostMode : selectedHost,
      } );

      console.log(updatedValues)
      
      let newTrader = createTrader(updatedValues);
      toast.promise(newTrader, {
        loading : 'Creating...',
        success : <b>Added Successfully 🙂</b>,
        error : <b>Added Faild 😟</b>
      });
      newTrader
      .then(() => {
        resetForm(); // reset form after successful submission
        // togglePopup(); //close the popup
      })
      .catch(function(error){
        const errMsg = error?.response?.data?.error ?? 'An error occurred';
        toast.error(<b>{errMsg}</b>); 
      })
    }
  })

  const handleButtonClick = (hostType) => {
    const selectedPlan = hostType === 'self' ? price[selectedOption]?.selfPlan : price[selectedOption]?.cloudPlan;
    setPlanId(selectedPlan);
    setSelectedHost(hostType);
    calAmount(hostType, `${price[selectedOption]?.price}`, selectedOption);
    setBtnPaypal(true); // Activate PayPal button
  };

  return (
    <>
    <div className='bg-slate-200 md:px-16 px-5 rounded-xl shadow-md
     shadow-sky-900 row-span-1 z-40 md:w-1/3 w-3/4' 
     style={{ maxHeight: '90vh', overflowY: 'auto' }}>

      <div className='flex justify-center items-center'>
          <h2 className='md:text-xl text-lg font-bold my-2'>Pay Here </h2>          
      </div>

      {/* Package */}
      <div className='flex justify-items-center'>
        <div className='m-3 '>Select Package : </div>
        <div className=' justify-center'>
          <div className="relative inline-flex">
          {/* <FormControl variant="standard"> */}
            <select 
            onChange={handleDropdownChange}
            className='block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 
            border-b-2 border-gray-200 appearance-none dark:border-gray-700 
            focus:outline-none focus:ring-0 focus:border-gray-200 peer'
            >
              <option>Select Packages</option>
                {price && price.length > 0 && price.map((item, index) => (
                  item && item.price !== null && item.price > 0 && (
                    <option key={index} value={index}>
                      {item.period} Month - Price: ${item.price}
                    </option>
                )
              ))}
            </select>
          {/* </FormControl> */}
          
        </div>
        </div>

        
      </div>

      <form autoComplete='off' onSubmit={handleSubmit} >
      {/* text-box */}
      <div className='grid md:gap-8 gap-4 items-center  '>
      { 
        <div>
          {selectedOption !== null && (
          <div className='flex justify-items-center'>
            <div className='m-3 '>Mt4 Account No : </div>
            <FormControl sx={{ m: 1, width: '20ch'}} variant="standard">
              {/* <InputLabel htmlFor="standard-adornment-password " >Account Id</InputLabel> */}
              <Input
              type='text' 
              id='account'
              required
              onChange={handleChange}
              value={values.account}
              onBlur={handleBlur}
              />
            </FormControl>
          </div>
          )}

          {/* select host mode */}
          <>
            {/* {values.account && (
              <div className='flex justify-items-center'>
              <div className='m-3'>Host Service : </div>
              <div className='flex justify-center my-2'>
                <Button 
                  color='error' 
                  sx={{borderRadius:3, mr: 2 }}  
                  variant={selectedHost === 'self' ? 'contained' : 'outlined'}
                  onClick={() => handleButtonClick('self')}
                >
                  Self 
                </Button>
                <Button 
                  color='info' 
                  sx={{borderRadius:2, mr: 2}}  
                  variant={selectedHost === 'cloud' ? 'contained' : 'outlined'}
                  onClick={() => handleButtonClick('cloud')}
                >
                  Cloud 
                </Button>
              </div>
              </div>
            )} */}
            {values.account && (
              <div className='flex justify-items-center'>
                <div className='m-3'>Host Service : </div>
                <div className='flex justify-center my-2'>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="host-service"
                      name="host-service"
                      value={selectedHost}
                      onChange={(event) => handleButtonClick(event.target.value)}
                      row
                    >
                      <FormControlLabel value="self" control={<Radio />} label="Self" />
                      <FormControlLabel value="cloud" control={<Radio />} label="Cloud" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            )}
          </>
          
          {selectedHost === 'cloud' ?
            <>
             <div className='flex justify-items-center'>
             <div className='m-3'>Mt4 Password : </div>
              {/* password */}
              <FormControl sx={{ m: 1, width: '20ch'}} variant="standard">
              {/* <InputLabel htmlFor="standard-adornment-password">Password</InputLabel> */}
              <Input
              type='text' 
              id='password'
              // required
              required={selectedHost === 'cloud'}
              onChange={handleChange}
              value={values.password}
              onBlur={handleBlur}
              />
              </FormControl>
              </div>
              
              {/* Server */}
              <div className='flex justify-items-center'>
              <div className='m-3'>Server Name : </div>
              <FormControl sx={{ m: 1, width: '20ch'}} variant="standard">
              {/* <InputLabel htmlFor="standard-adornment-password">Server</InputLabel> */}
              <Input
              type='text' 
              id='server'
              required={selectedHost === 'cloud'}
              onChange={handleChange}
              value={values.server}
              onBlur={handleBlur}
              />
              </FormControl>
              </div>
            </>
            :<></>}
        </div>
      }
      </div>

      {/* total */}
      <div className='m-3'>Total Amount : 
        <span className='mx-3 font-semibold'>
        ${hostAmmount}.00</span> 
      </div>
      
      {/* paypal subscription btn */}
      {btnpaypal && planId &&(
        <PayPalScriptProvider  key={planId} options={{ "client-id": CLIENT_ID, "vault": true }}>
         {/* { console.log(selectedHost)} */}
          <PayPalButtons
          createSubscription={(data, actions) => {
            const accountId = values.account;
            const concatenatedData = `${accountId}-${email}-${selectedHost}-${channel.channelName}`;
            console.log(accountId)
            console.log(concatenatedData)

            return actions.subscription.create({
                plan_id: `${planId}`,
                custom_id: `${concatenatedData}`,
                application_context: {
                  brand_name: 'Your Brand Name',
                  user_id: `${email}`, // Example user ID
                  hostMode: `${selectedHost}`
                }
              });
            }}
            onApprove={onApprove}
            style={{
              label: "subscribe",
            }}
          />
        </PayPalScriptProvider>
      )} 

      {/* confirm button */}
      <div className='flex justify-center items-end my-5 pt-3'>

        { btnConfirm ?
        (
          <Button 
            type='submit' 
            // color='success' 
            sx={{borderRadius:3}}  
            variant='contained'>
            confirm
          </Button>
        ) : null
        }
      </div>
      
      </form>

    </div>
    </> 
  )
}

export default Payments