import { useEffect, useCallback, useState } from "react";
import { getuserName } from '../helper/helper'
import axiosInstance from '../helper/axios-url'

const axios = axiosInstance;

/** custom hook */
export default function useFetch(){
    const [getData, setData] = useState({ isLoading : false, apiData: null, status: null, serverError: null, userEmail:null });
    

    const fetchData = useCallback(async () => {
        try {
            const { username } = await getuserName();
           
            setData(prev => ({ ...prev, isLoading: true }));
            const { data, status } = await axios.get(`/api/trader/${username}`);
            if(status === 201) {
                setData(prev => ({ ...prev, isLoading: false, apiData: data, status, userEmail:username }));
            }
            setData(prev => ({ ...prev, isLoading: false }));
        } catch (error) {
            setData(prev => ({ ...prev, isLoading: false, serverError: error }));
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);
 
    return [getData];
}
