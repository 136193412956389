import React,{useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

//footer+navbar
import Footer from "./component/footer/footer";
// import NavBar from './component/navbar/navBar';

//home
import HomeScreen from './component/home/HomeScreen';

// ** loarding **
import PropagateLoader from "react-spinners/PropagateLoader";

//Auth Modules
import Login from './component/authModule/Login'
import Register from './component/authModule/Register';
import OTPVerify from './component/authModule/OTPVerify';
import Reset from './component/authModule/Reset';

// auth middleware 
import { AuthorizeUser } from './component/authModule/middleware/auth-route';

//provider
import CreateChannel from './component/signalProvider/CreateChannel'
import Subscriber from './component/subscriber/Subscriber';
import Customize from './component/signalProvider/Customize';

//Subscriber
import SubscriberSetUp from './component/subscriber/Setup';
import ProviderSetUp from './component/signalProvider/Setup';
import MyChannels from './component/subscriber/profile/MyChannels';

// Administrator
import Administrator from './component/administrator/Administrator'

//super-user
import SuperUser from './component/superUser/SuperUser';

// ************************ Permission *******************
import {isAllowed} from './component/permManager/ManagePerm'
//---------------------------------------------------------

import ChannelDetails from './component/signalProvider/ChannelDetails';
import Payments from './component/signalProvider/Payments';

function App() {

const [loading, setLoading] = useState(false);

useEffect(() => {
  setLoading(true)

  setTimeout(() => {
    setLoading(false)  
  }, 5000);

}, []);

  return (
    <div>
    {loading ? (
      <div className='loading'>      
        <PropagateLoader color="black" />
      </div>   
    ):(
      <>
      {/* <BrowserRouter > */}
      {/* <NavBar/> */}
        <Routes>
          <Route path = '/' element = { <HomeScreen /> } />

          {/* Auth routes */}
          <Route path = '/login' element = { <Login /> } />
          <Route path = '/register' element = { <Register /> } />
          <Route path = '/get-otp' element = { <OTPVerify /> } />
          <Route path = '/reset' element = { <Reset /> } />

          {/* Povider/master */}
          <Route path = '/create-channel' 
          element = { <AuthorizeUser > 
            <CreateChannel showCreate={isAllowed('CHANNEL_CREATE')} channelDelete={isAllowed('CHANNEL_DELETE')} /> 
          </AuthorizeUser> } />  

          {/* customize */}
          <Route path = '/provider-customize' element = { <AuthorizeUser > <Customize /> </AuthorizeUser> } />
          
          <Route path = '/provider-setup' element = { <AuthorizeUser > <ProviderSetUp /> </AuthorizeUser> } />
          
          {/* Subscriber/ */}          
          <Route path = '/index' 
          element = { <AuthorizeUser > 
            <Subscriber subsBtn={isAllowed('CHANNEL_SUBS')} /> 
          </AuthorizeUser> } />

          <Route path = '/my-channels' element = { <AuthorizeUser > <MyChannels /> </AuthorizeUser> } />
          <Route path = '/subscriber-setup' element = { <AuthorizeUser > <SubscriberSetUp /> </AuthorizeUser> } />

          {/* Administrator */}
          <Route path = '/all-Providers' 
          element = {<AuthorizeUser > 
          <Administrator showDelete={isAllowed('USER_DELETE')} /> 
          </AuthorizeUser>} />

           {/* Super User  */}
           <Route path = '/change-role' element = {<AuthorizeUser ><SuperUser /></AuthorizeUser>  } />

           {/* channel details */}
           <Route path = '/channel-details/:channelId' element = {<AuthorizeUser ><ChannelDetails subsBtn={isAllowed('CHANNEL_SUBS')} /></AuthorizeUser>} />
           
           {/* payments */}
           <Route path = '/pay' element = {<Payments />} />

        </Routes>
        < Footer />
     {/* </BrowserRouter> */}
      </>
    )
    }
    </div>
  );
}

export default App;
