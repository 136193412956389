import React, {useState, useEffect} from 'react'
import Navbar from '../navbar/Navbar'

// meterial-ui
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';

// react icon
import { BiCopy} from 'react-icons/bi';

// sweet alert
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

//
import { getuserName, updateDomain } from '../authModule/helper/helper';

const Customize = () => {
  const [subdomain, setSubdomain] = useState('');
  const [copied, setCopied] = useState(false);
  const [email, setEmail] = useState('')
  const [showLink, setShowLink] = useState(false)
  

  // Assuming you have a function to handle subdomain creation
  const handleSubdomainCreation = () => {
    const createdSubdomain = subdomain; // Example subdomain
    //console.log(subdomain, email)
    const data = {subdomain, email}

    //updateDomain(data)

    updateDomain(data)
    .then(response => {
      // console.log(response)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          showConfirmButton: false,
          timer: 1000,
          text: response.data.message
        });
        setSubdomain(createdSubdomain);
        setCopied(false);
        setShowLink(true)
      } 
    })
    .catch(error => {
      if (error.response.status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          showConfirmButton: false,
          timer: 1500,
          text: error.response.data.message
        });
      }
      //console.error('Error:', error);
    });
    
  };

  const handleCopy = () => {
    const copyText = `http://${subdomain}.portal.fxtxcopier.com/`;
  
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copyText)
        .then(() => {
          setCopied(true);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Copied!",
            showConfirmButton: false,
            timer: 1000
          });
        })
        .catch((err) => {
          console.error('Unable to copy to clipboard', err);
        });
    } else {
      // Fallback for browsers that don't support navigator.clipboard
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setCopied(true);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Copied!",
        showConfirmButton: false,
        timer: 1000
      });
    }
  };
  

  // get the user id
  useEffect(() => {
    //get the email
  function getUserEmail(){
    getuserName().then(result => {
      const email = result.username;
      setEmail(email)
      //console.log(email)
    })
  }
  getUserEmail();

  }, []);

  return (
    <>
    <Navbar />
    <div className='container'>
    <div className='md:mt-16 mt-12 bg-slate-100 rounded-2xl p-5'>
      <h3 className='flex justify-center md:text-2xl text-xl md:p-3'>Customize Your Service</h3>
      <div className='m-5'>
      <div className='grid grid-rows-2 '>
        <div>
        <label htmlFor="sub-domain" className='text-slate-600' >
          Create the Sub-Domain
        </label>
        </div>
        <div>
          <span className='pr-2'>http://</span>
          <Input type="text" value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
          <span>.portal.fxtxcopier.com</span>
        
        {subdomain && (
          <Button
            sx={{borderRadius:3, marginLeft: '10px'}} 
            variant='outlined' 
            onClick={handleSubdomainCreation}>Create
          </Button>
        )}

        </div>
      </div>

      <div className='mt-5'>
      
      {showLink && (
        <>
        <span className='text-slate-600'>Your Own URL</span>
        <div className='mt-3'>
           <span className='text-blue-600 underline'> http://{subdomain}.portal.fxtxcopier.com </span>

          <button onClick={handleCopy} className='text-blue-500 mx-3'><BiCopy/></button>

        </div></>
      )}
      </div>
      </div>
    </div>     
    </div>
    </>
  )
}

export default Customize