import React,{ useState, useEffect, useRef } from 'react'
// react Icons
import { ImCross } from 'react-icons/im';
//import { BiDownArrow } from 'react-icons/bi';

///mui
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import styles from '../subscriber/styles/style.module.css';
import toast,{Toaster} from 'react-hot-toast';
import { useFormik } from 'formik';
import { createChannel, getChannelById, getuserName } from '../authModule/helper/helper';

//default user
//import img from '../../assets/img/user.png';

import ChannelList from './ChannelList'

//import base-64
import convetBase64 from '../authModule/helper/convert';

//import Navbar from './Navbar';
import Navbar from '../navbar/Navbar';

// import edit channel
import EditChanel from './EditChanel';

// import paypal subscription plan
import {createSubscriptionPlans, createSubscriptionPlan} from '../PaypalSubscription/PaypalSub';

const ProviderProfile = (props) => {

  const {channelDelete} = props;
  //console.log(channelDelete)

  const [email, setEmail] = useState('');
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);

  // create channel popup
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState(null);

  //logo-upload
  const [file, setFile] = useState([]);
  //banner-upload
  const [banner, setBanner] = useState([]);
  //for flayers
  const [showBannersInput, setShowBannersInput] = useState(false);
  //upload banners
  const [selectedImages, setSelectedImages] = useState([]);
  // for pricing
  const [price, setPrice] = useState([null, null, null, null]);
  // submit btn
  const [submitted, setSubmitted] = useState(false);
  // load channel details
  const [loadChannelDetails, setLoadChannelDetails] = useState(false);

  // setDetail
  const togglePopup = () => {
    setDetails(null)
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getUserEmail();

    const fetchData = async () => {
      try {
        setLoadChannelDetails(true);
        const response = await getChannelById(selectedChannel);
        //console.log('click the channel ', selectedChannel)
        const { data } = response;
        setLoadChannelDetails(false)
        setDetails(data);
       // console.log(details)
      } catch (error) {
        console.error(error);
      }
    };
    
    if (selectedChannel) {
      fetchData();
    }

  },[selectedChannel]);

  //outside click
  const refUser = useRef(null);
  //outside click
  useEffect(() => {
    // Function to handle outside click
    function handleClickOutside(event) {
      if (refUser.current && !refUser.current.contains(event.target)) {
        setIsOpen(false)
      } else {
        //setIsClickedOutside(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // select the Channel
  async function handleChannelSelect(channel) {
    setSelectedChannel(channel);
  }

  //get the email
  function getUserEmail(){
    getuserName().then(result => {
      const email = result.username;
      setEmail(email)
      //console.log(email)
    })
  }

  // for logo upload
  const onUpload = async e =>{
    const base64 = await convetBase64(e.target.files[0]);
    setFile(base64); 
  }

  // for banner upload
  const onUploadBanner = async e =>{
    const cover = await convetBase64(e.target.files[0]);
    setBanner(cover); 
  }
  
  const handleImageUpload = async e => {
  const files = e.target.files;
  const base64Images = [];

  for (let i = 0; i < files.length; i++) {
  const base64 = await convetBase64(files[i]);
  base64Images.push(base64);

  if (i === files.length - 1) {
    const combinedImages = [...selectedImages, ...base64Images].slice(0, 5); // Limit to maximum of 5 images
    setSelectedImages(combinedImages);
  }
  }
  };

  const handleImageDelete = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const handleCheckboxChange = (index) => {
    const updatedPrice = [...price];
    updatedPrice[index] = updatedPrice[index] === null ? 0 : null;
    setPrice(updatedPrice);
  };

  // submit
  const {values, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
    initialValues : {
      channelName : '',
      source : 'MT4',
      accountID : '',
      email : ''
    },
   // validationSchema : registerSchema,
    onSubmit : async values => {  

      try {

        setSubmitted(true); //submit btn 

        //create subscription plans
        const newArray = await createSubscriptionPlans(price);
        console.log('Updated prices array:', newArray);

        const updatedValues = Object.assign({}, values, { email }, {logo: file}, 
          {flayers: selectedImages}, {cover: banner}, {price: newArray});

          // console.log(price)

          // let newChannel = createChannel();
          let newChannel = createChannel(updatedValues);
          toast.promise(newChannel, {
            loading : 'Creating...',
            success : <b>Added Successfully 🙂</b>,
            error : <b>Faild to Create 😟</b>
          });
          newChannel
          .then(() => {
            setChannels([...channels, newChannel]); 
            resetForm(); // reset form after successful submission
            togglePopup()
          })
          .catch(function(error){
            setSubmitted(false);
            const errMsg = error?.response?.data?.error ?? 'An error occurred';
            toast.error(<b>{errMsg}</b>); 
          }
        )
        
      } catch (error) {
        console.log('Error Sreating Subscription Plan', error);
      }
    }
  })
  
  return (
    <>
    <Navbar/>
    
    <div className='container'>
      {/* toast message */}
      <Toaster position='top-center' reverseOrder={false} />

      <div className='grid grid-cols-1 md:grid-cols-3 md:gap-10 '>

        {/** this is the create Channel **/}
        <div>
          <>
          {props.showCreate ? ( 
            <div className='flex pb-3'>
              <Button 
              onClick={togglePopup} 
              style={{color:"white"}}
              sx={{borderRadius:3}} 
              variant='contained'>
                New Channel
              </Button>
            </div>
          ) : null }
          </>

          {/** this is Channel List **/}
          <div className='row-span-2'>
            <ChannelList 
            channels={channels} 
            onChannelSelect={handleChannelSelect} 
            deleteChannel={channelDelete}
            //setDetail={details} 
            />
          </div> 
        </div>        
        
        {/** this is Channel Details **/}
        {/* {loadChannelDetails && (
          <div>Loading...</div>
        )} */}

        {details ? (     
          <div className='col-span-2 row-span-1 bg-slate-100 rounded-lg shadow-2xl capitalize'>
            <EditChanel details={details} index={details.data.client.accountID} key={details.data.client.accountID} />
          </div>
         ) : (
            loadChannelDetails && <div>Loading...</div>
          )
        }

        {/* create New Channel popup */}
        {
          isOpen && (
            <div className='bg-opacity-100 opacity-100 fixed inset-0 z-20'>
              <div className="fixed inset-0 backdrop-blur-sm" />
              <div ref={refUser} className="flex h-screen justify-center items-center">
                <div className='bg-slate-200 md:px-16 px-5 rounded-xl shadow-md shadow-sky-900 row-span-1 z-40 md:w-1/4 ' style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    
                  <div className='flex justify-center items-center'>
                    <h2 className='md:text-xl text-lg font-bold my-4'>Create New Channel</h2>          
                  </div>
                  
                  <form autoComplete='off' onSubmit={handleSubmit}  >

                    {/* text-box */}
                    <div className='grid items-center'>

                      <FormControl sx={{ m: 1, width: '25ch'}} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password " >Channel Name</InputLabel>
                        <Input
                          type='text' 
                          id='channelName'
                          onChange={handleChange}
                          value={values.channelName}
                          onBlur={handleBlur}
                          className={styles.textBox}
                          //style={{color:"white"}}
                          required
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1, width: '25ch'}} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password " >Source Type</InputLabel>
                        <Input
                          type='text'
                          id="source" 
                          onChange={handleChange}
                          value={values.source}
                          onBlur={handleBlur}
                          className={styles.textBox}
                          //style={{color:"white"}}
                          required
                        />
                      </FormControl>

                      <FormControl sx={{ m: 1, width: '25ch'}} variant="standard">
                        <InputLabel htmlFor="standard-adornment-password " >Account Id</InputLabel>
                        <Input
                          type='text' 
                          id='accountID'
                          onChange={handleChange}
                          value={values.accountID}
                          onBlur={handleBlur}
                          className={styles.textBox}
                          //style={{color:"white"}}
                          required
                        />
                      </FormControl>

                      {/* pricing */}
                      <FormControl sx={{ m: 1, width: '25ch'}} variant="standard">
                        <Typography variant="subtitle1" className='text-slate-700'>Price Range</Typography>
                        <div className='grid md:grid-cols-2 gap-3'>
                          {[1, 3, 6, 12].map((period, index) => (
                            <div key={index}>
                              <label>
                                <input
                                className='m-1'
                                  type='checkbox'
                                  checked={price[index] !== null}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                                For {period} Month
                              </label>
                              {price[index] !== null && (
                                <div className='grid grid-cols-2 m-1'>
                                  <Input
                                    //type='number'
                                    value={price[index] || ''}
                                    onChange={(e) => setPrice(prevPrice => {
                                      const updatedPrice = [...prevPrice];
                                      updatedPrice[index] = parseInt(e.target.value, 10);
                                      return updatedPrice;
                                    })}
                                    placeholder={`$:Price`}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </FormControl>
  
                      {/* logo */}
                      <FormControl sx={{ m: 1, width: '25ch'}} variant="standard">
                        {/* <InputLabel htmlFor="standard-adornment">Select Logo</InputLabel> */}
                        <Typography variant="subtitle1" className='text-slate-700'>Select Logo</Typography>
                        <input
                          type='file'
                          className='cursor-pointer'
                          onChange={onUpload}  
                          required
                        />
                      </FormControl>
                      {/* cover image */}
                      <FormControl sx={{ m: 1, width: '25ch'}} variant="standard">
                        {/* <InputLabel htmlFor="standard-adornment">Select Logo</InputLabel> */}
                        <Typography variant="subtitle1" className='text-slate-700'>Cover Image</Typography>
                        <input
                          type='file'
                          className='cursor-pointer'
                          onChange={onUploadBanner}  
                          required
                        />
                      </FormControl>
                     
                      {/* banner Upload area */}
                      <span
                        className='my-5 mx-3 cursor-pointer'
                        onClick={() => setShowBannersInput(!showBannersInput)}
                      >
                        <Typography variant="subtitle1" className='text-slate-700'>Select Flayers</Typography>
                        <div className='border-b border-gray-500 flex ' />
                          {/* <>
                          <BiDownArrow/></> */}
                      </span>

                      {showBannersInput && (
                        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                          <div>
                            <div>
                              {selectedImages.map((image, index) => (
                                <div key={index} style={{ position: 'relative', display: 'inline-block', width: '40px', height: '40px', margin: '2px' }}>
                                  <img src={image} alt={`Image ${index}`} width="50" height="50" className='w-full h-full object-cover' />
                                  <button  onClick={() => handleImageDelete(index)} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                  <ImCross color='red' />
                                  </button>
                                </div>
                              ))}
                            </div>
                            <input type="file" multiple onChange={handleImageUpload} />
                          </div>
                        </FormControl>
                      )}
                      
                    </div>

                    {/* buttons */}
                    <div className='flex justify-center my-5 mt-2'>
 
                      <Button 
                      type='submit' 
                      // color='success'
                      disabled={submitted}
                      sx={{borderRadius:3}} 
                      variant='contained'>
                        {/* Create */}
                        {submitted ? 'Creating...' : 'Create'}
                      </Button>
                    </div>

                  </form>

                </div> 
              </div>
            </div>       
          ) 
        }
      </div>
    </div>
    </>
  )
}

export default ProviderProfile