// this file use to convert the image
// export default function convetBase64(file){
//     return new Promise((resolve, reject) =>{
//         const fileReader = new FileReader()
//         fileReader.readAsDataURL(file);

//         fileReader.onload = () => {
//             resolve(fileReader.result)
//         }

//         fileReader.onerror = (error) => {
//             reject(error)
//         }
//     })
// }


export default function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const MAX_FILE_SIZE = 1000000; // 1MB in bytes
  
      if (file.size <= MAX_FILE_SIZE) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
  
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
  
            const scaleFactor = Math.min(MAX_FILE_SIZE / file.size, 1);
  
            canvas.width = img.width * scaleFactor;
            canvas.height = img.height * scaleFactor;
  
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
            canvas.toBlob((blob) => {
              const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
              const resizedFileReader = new FileReader();
  
              resizedFileReader.readAsDataURL(resizedFile);
  
              resizedFileReader.onload = () => {
                resolve(resizedFileReader.result);
              };
  
              resizedFileReader.onerror = (error) => {
                reject(error);
              };
            }, 'image/jpeg', 0.3);
          };
        };
  
        reader.readAsDataURL(file);
      }
    });
  }
  