import React,{ useState, useEffect, useRef, useCallback } from 'react';

// tostMessage
import toast,{Toaster} from 'react-hot-toast';

// import not-found page
import NotFoundPage from '../NotFoundPage';

// mui 
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Button from '@mui/material/Button';

import { Link } from 'react-router-dom';

import useFetchChannels from '../hook/featch-allChannel';

//import the axios function
import { getChannels, getuserName, getSubchannel, unsubscribeChannel, getChannelsBySubdomain } from '../authModule/helper/helper';

//nav-bar
import Navbar from '../navbar/Navbar';
import img from '../../assets/img/user.png';

//import paypal
import Payments from '../signalProvider/Payments';

// import single page
import ChannelDetails from '../signalProvider/ChannelDetails';
// sweet alert
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

//number of cards in the page
const PageSize = 12;

const Subscriber = (props) => {

  const [email, setEmail] = useState(''); 
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [channelId, setChannelId] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // payment popup
  const [subscribe, setSubscribe] = useState([]); //subscribeChannels
  const [subscribedData, setSubscribedData] = useState([]);
  const [isChannelOpen, setIsChannelOpen] = useState(false); // Channel popup

  const [subdomain, setSubdomain] = useState();//get subdomain
  const [pageNotFound, setPageNotFound] = useState(false);//pagenotfound

  // paginaton
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / PageSize);
  const startIndex = (currentPage - 1) * PageSize;
  const endIndex = Math.min(startIndex + PageSize, data.length);
 
  // get all channel
  const[{ apiData, serverError}] = useFetchChannels();

  //Next and previous
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Load channels
  useEffect(() => {
    
    const hostname = window.location.hostname;
    const arr = hostname.split('.');

    let domain = '';

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === 'portal') {
        if (i > 0) {
          domain = arr[i - 1];
        }
        break; // Stop the loop when 'e-trade-space' is found
      }
    }

    if (domain) {
      setSubdomain(domain);
      console.log(domain);
    } else {
      console.log('No subdomain detected.');
    }
  
    // async function fetchData() {
    //   try {
    //     if (domain) {
         
    //       const response = await getChannelsBySubdomain(subdomain);
    //       setPageNotFound(false);
    //       console.log('Subdomain Data:', response);
    //       setData(response.data);
         
    //     } else {
    //       const response = await getChannels();
    //       //console.log('General Data:', response.data);
    //       setData(response.data);
    //     }
    //   } catch (error) {
    //     console.error('Fetch Data Error:', error);
    //     if(error.response && error.response.status === 404){
    //       setPageNotFound(true)
    //     }
        
    //   } finally {
    //     setIsLoading(false);
    //   }
    // }
  
    fetchData(domain);
    getUserEmail()
  
  }, [subdomain]);
  
  // Payment Popup
  const togglePopup = (channel) => {
    setIsOpen(!isOpen);
    const channelid = channel.account
    setChannelId(channelid);
   //console.log(channel.account)
  };

  // single channel
  const popupSinglePage = (chanels) => {
    //console.log(chanels)
    setIsChannelOpen(!isChannelOpen)
    setChannelId(chanels);    
  }

  //outside click
  const refElement = useRef(null);

  //outside click
  useEffect(() => {
    // Function to handle outside click
    function handleClickOutside(event) {
      if (refElement.current && !refElement.current.contains(event.target)) {
        setIsOpen(false)
        setIsChannelOpen(false)
      } else {
        //setIsClickedOutside(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //get the user email
  const getUserEmail = useCallback(async () => {
    getuserName().then(result => {
      const userEmail = result.username;
      //console.log(userEmail)
      setEmail(userEmail);
      subChannels(userEmail)
    })
  }, []);

  //featch the data
  const fetchData = useCallback(async (domain) => {
    try {
      if (domain) {
       console.log(domain)
        const response = await getChannelsBySubdomain(subdomain);
        setPageNotFound(false);
        console.log('Subdomain Data:', response);
        setData(response.data);
       
      } else {
        const response = await getChannels();
        //console.log('General Data:', response.data);
        setData(response.data);
      }
    } catch (error) {
      console.error('Fetch Data Error:', error);
      if(error.response && error.response.status === 404){
        setPageNotFound(true)
      }
      
    } finally {
      setIsLoading(false);
    }
  }, []);


  //get the subscribe channels
  function subChannels(email){
    getSubchannel(email)
    .then((response) => {
      const subscribed = response.data.map((sub) => sub.channelName);
      setSubscribedData(subscribed);
    });
  }

  //unsubscribe the channel
  function unsubscribe(chanels) {
    Swal.fire({
      title: 'Do you wanna unsubscribe?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      customClass:{
        popup:'my-popup-class'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { chanels, email };
        console.log(data)
        unsubscribeChannel(data);
      } else {
        console.log('Unsubscribe canceled.');
      }
    });
  }

  // page not Found
  if (pageNotFound) {
    return <NotFoundPage />;
  }
 
  return (
    <div>
      <Navbar />  
      <div className='container'>                     
        {/* toast message */}
        <Toaster position='top-center' reverseOrder={false} />
        <div className='grid grid-cols-1'>

          {/** this is the edit profile **/}
          <div className=' md:row-span-2 md:px-16 px-5 rounded-xl '>
            <div className='grid md:grid-cols-4 grid-cols-1 gap-5 md:my-10'>
              {isLoading ? (  
                <div>Loading...</div>
                ) : (
                  
                  data?.slice(startIndex, endIndex).map((chanels) => (
                  //apiData.map((chanels) => (
                  <div key={chanels._id}>
                    
                    <Card sx={{ maxWidth: 345, borderRadius:3 }} className='cursor-pointer '>
                      {/* <Link to={`/channel-details/${chanels.account}`}> */}
                        <CardHeader
                          avatar={
                            <Avatar src={chanels.logo || img} aria-label="recipe" ></Avatar>
                          }
                          // action={
                          //   <Stack direction="row" >
                          //     <Chip label="Risk" color="success" />
                          //   </Stack>
                          // }
                          title={
                            <Typography variant="h6" component="h3" style={{ fontWeight: 'bold' }}>
                              {chanels.channelName}
                            </Typography>
                          }
                          subheader="High achive"
                          onClick={() => popupSinglePage(chanels.account)}
                        />
                      {/* </Link> */}

                      <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <div style={{ flex: 1, margin: '0 10px' }}>
                          <Typography variant="body1" color="text.primary">
                            Copiers
                          </Typography>
                          <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold', alignItems: 'center' }}>
                            510
                          </Typography>
                        </div> */}
                        <div style={{ flex: 1, marginLeft: '10px' }}>
                          <Typography variant="body1" color="text.primary">
                            Price
                          </Typography>
                          <Typography variant="body2" color="text.secondary" style={{ fontWeight: 'bold' }}>
                          {`$ `+ chanels.price[0]?.price || chanels.price[1]?.price || chanels.price[2]?.price || chanels.price[3]?.price}
                          </Typography>
                        </div>
                      </CardContent>

                      {/* acctions */}
                      {
                        props.subsBtn ? (
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                          {
                            subscribedData && subscribedData.includes(chanels.channelName) ? (
                              <Button 
                              onClick={() => unsubscribe(chanels.channelName)}
                              variant='outlined' 
                              sx={{borderRadius:3}} 
                              color='primary'>Unsubscribe</Button>
                            ):(
                              <Button 
                              onClick={() => togglePopup(chanels)}
                              variant='contained' 
                              sx={{borderRadius:3}} 
                              color='primary'>Subscribe</Button>
                            )
                          }
                        </CardActions> ) : (null)
                      }
                    </Card>
                  </div>
                ))
              )}
            </div>

            {/* pagination btn */}
            <div className="flex justify-end my-3 ">
              <div className="pagination">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className={`px-1 py-1 cursor-pointer rounded-md ${
                    currentPage === 1 ? 'bg-gray-400' : 'bg-blue-500'
                  }`}
                >
                  <NavigateBeforeIcon />
                </button>
                <span className="px-2 py-2">{`${currentPage} / ${totalPages}`}</span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`px-1 py-1 cursor-pointer rounded-md ${
                    currentPage === totalPages ? 'bg-gray-400' : 'bg-blue-500'
                  }`}
                >
                  <NavigateNextIcon /> 
                </button>
              </div>
            </div> 
          </div>

          {/* Payment Popup */}
          {
          isOpen && (
            <div className='bg-opacity-100 opacity-100 fixed inset-0 z-20'>
              <div className="fixed inset-0 backdrop-blur-sm" />

              <div ref={refElement} className="flex h-screen justify-center items-center">
                {/* add the payment componet */}
                <Payments channelId={channelId}/>
              </div>
            </div> 
          )
          }

          {/* Single Page load */}
          {
            isChannelOpen && (
              <div className='bg-opacity-100 opacity-100 fixed inset-0 z-20'>
              <div className="fixed inset-0 backdrop-blur-sm"/>
                <div ref={refElement} className="flex h-screen justify-center items-center">
                  {/* add the payment componet */}
                  <ChannelDetails props={props} channelId={channelId} />
                </div>
            </div> 
            )
          }

        </div>
      </div>
    </div>
  )
}

export default Subscriber